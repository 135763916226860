import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  input: {
    width: '100%',
    borderRadius: '8px',
    height: '30px',
    border: 0,
    marginBottom: '16px',
    fontSize: '16px'
  }
});

type InputType = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';

interface FormInputProps {
  type: InputType;
  name: string;
  initialValue?: string | number;
  required?: boolean;
  id: string;
  hidden?: boolean;
  display?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({type = 'text', name, id, required = false, initialValue = '', hidden=false, display=true}) => {
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();

  const handleChange = (val: string | number) => {
    setValue(val);
  }
  
  return (
    <input
      style={{display: !display ? 'none' : ''}}
      className={classes.input}
      type={type}
      name={name}
      value={value}
      id={id}
      required={required}
      hidden={hidden}
      onChange={(e) => handleChange(e.currentTarget.value)}
    />
  );
};

export default FormInput;
