import React from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {
    borderRadius: '90px',
    width: '150px',
    height: '150px',
    marginLeft: '-20px',
    opacity: 0.9,
    boxShadow: '2px 2px 6px #363636',
    border: '1px solid #363636'
  }
});

interface ImageCircleProps {
  imageUrl: string;
}

const ImageCircle: React.FC<ImageCircleProps> = ({ imageUrl }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundImage:`url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className={classes.main}></div>
  );
};

export default ImageCircle;
