import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { primaryColor } from '../../styles';

const useStyles = createUseStyles({
  main: {
    width: '100%',
    textAlign: 'left',
  },
  input: {
    width: '120px',
    height: '60px',
    borderRadius: '8px',
    border: 0,
    marginBottom: '16px',
    backgroundColor: primaryColor,
    fontSize: '20px',
    fontWeight: 600,
    color: '#fff',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      opacity: 0.5
    }
  }
});

interface InputSubmitProps {
  name: string;
  buttonText?: string;
  required?: boolean;
  id: string;
}

const InputSubmit: React.FC<InputSubmitProps> = ({name, id, required = false, buttonText = 'Submit'}) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <input
        className={classes.input}
        type='submit'
        name={name}
        value={buttonText}
        id={id}
      />
    </div>
  );
};

export default InputSubmit;
