import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Main from './pages/Main';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Main />
      </div>
    </HelmetProvider>
  );
}

export default App;
