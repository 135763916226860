import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import Nav from './Nav';
import Logo from './Logo';

const useStyles = createUseStyles({
  main: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    borderBottom: '1px solid #000',
    alignItems: 'center',
    marginBottom: '20px',
    '@media (max-width: 500px)': {
      height: '100px',
      position: 'fixed',
    }
  },
  logoBox: {
    width: '20%',
    '@media (max-width: 500px)': {
      width: '50%'
    }
  },
  navBox: {
    width: '60%',
    height: '100%',
    '@media (max-width: 500px)': {
      width: '50%',
      textAlign: 'right'
    }
  },
  contactBox: {
    width: '20%'
  },
});




interface HeaderProps {

}

const Header: React.FC<HeaderProps> = () => {
  const [scrollY, setScrollY] = useState(window.scrollY);
  const mobile = window.matchMedia('(max-width: 500px)').matches;
  const classes = useStyles();

  const handleScroll = (e: any) => {
    setScrollY(e.currentTarget.scrollY);
  }

  useEffect(() => {
    // Adding the scroll listener
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        // Removing listener
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <header className={classes.main} style={{
      height: mobile ? '100px' : scrollY > 100 ? '40px' : '100px',
      transition: 'all .2s ease-in-out'
    }}>
      <div className={classes.logoBox}><Logo scrollY={scrollY} /></div>
      <div className={classes.navBox}>
        <Nav />
      </div>
    </header>
  );
};

export default Header;
