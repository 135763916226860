import React from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {
    width: '100%',
    textAlign: 'left',
    margin: '4px auto'
  },
  label: {}
});

interface FormLableProps {
  labelText: string;
  htmlFor: string;
  hidden?: boolean;
  display?: boolean;
}

const FormLable: React.FC<FormLableProps> = ({ labelText, htmlFor, hidden=false, display=true }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      <label style={{display: !display ? 'none' : ''}} className={classes.label} hidden={hidden} htmlFor={htmlFor}>{labelText}</label>
    </div>
  );
};

export default FormLable;
