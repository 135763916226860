import React from 'react';
import { createUseStyles } from 'react-jss';
import { ServiceCard, Divider } from '../components';
import { Helmet } from 'react-helmet-async';
import Design from '../assets/Design-Person.jpg';
import Websites from '../assets/Websites.jpg';
import Applications from '../assets/software-collab.jpg';
import Embedded from '../assets/Embedded.jpg';

const useStyles = createUseStyles({
  main: {},
  pageTitle: {
    fontWeight: 400,
    fontSize: '3em',
    margin: 0,
    padding: '64px 0 32px 0',
    letterSpacing: '6px',
  },
  hr: {
    width: '40%',
    height: '2px',
    border: 'none',
    background: 'linear-gradient(to left, #fff, #6495EDcc, #6495EDcc, #fff)'
  },
  servicesContent: {
    width: '100%',
  },
  cardContainer: {
    width: '100%',
    
  },
});

const Bar = () => {
  return (
    <span style={{color: '#363636', opacity: 0.4, margin: 0}}>|</span>
  )
};

const Services = () => {
  const classes = useStyles();

  const services = [
    {
      imageUrl: Applications,
      imageAlt: 'People gathered arond a table',
      title: 'Custom Applications',
      description: 'At Software Delight we transform your vision into reality. You have ideas, we have the technical know-how to bring those ideas to fruition.  We create user-centric digital masterpieces',
      bulletPoints: ['Innovative Solutions', 'Precision Development', 'Speed and Quality'],
      navAnchor: 'custom_applications',
    },
    {
      imageUrl: Websites,
      imageAlt: 'Hands working on a laptop',
      title: 'Website Development',
      description: 'Whether it\'s getting your small business on the radar, creating your e-commerce site, updating or reimagining your online presence, we have what it takes to finally bring your vision to life.',
      bulletPoints: ['Perfomance Focused', 'Customizable Solutions ', 'The Experience to Guide You'],
      navAnchor: 'website_development',
    },
    {
      imageUrl: Design,
      imageAlt: 'Office room with computers',
      title: <>UI/UX <Bar /> Design</>,
      description: 'Industry expertise and experience to get the most brilliance out of your wonderful idea! We can bring rare insight into how your users feel and interact with your product.',
      bulletPoints: ['User Centric Expertise', 'Years of Industry Experience', 'Rare insight Into User Behavior'],
      navAnchor: 'uiux_design',
    },
    {
      imageUrl: Embedded,
      imageAlt: 'Woman at workbench',
      title: <>Firmware <Bar /> Hardware <Bar/> IOT Devices</>,
      description: 'Fast prototyping for connecting hardware to monitoring applications.  Quickly quantify & aggregate sensor data, or creating device firmware from scratch.  From IOT devices to more advanced hardware applications, we can get you there.',
      bulletPoints: ['Quick IOT Prototyping', 'Firmware & Embedded Software Design', 'Custom Hardware Monitoring & Control'],
      navAnchor: 'firmware_hardware',
    }
  ];

  return (
    <div className={classes.main} id='services'>
      {/* <Helmet>
        <title>Software Delight Custom Software Development Agency and Consulting Services</title>
        <meta name="description" content="Services of Software Delight custom software development agency and consulting. Digital Marketing, IOT, EMbedded software design and development. Websites.  Custom Applications. Software Consulting. Denver, Colorado"/>
      </Helmet> */}
      <h2 className={classes.pageTitle}>Spectrum of Quality Services</h2>
      <hr className={classes.hr}></hr>
      <div className={classes.servicesContent}>
        {
          services.map((service, index) => 
            <div className={classes.cardContainer} key={index}>
              <ServiceCard 
                imageUrl={service.imageUrl}
                imageAlt={service.imageAlt}
                title={service.title}
                text={service.description}
                index={index}
                bulletPoints={service.bulletPoints}
                navAnchor={service.navAnchor}
              />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Services;
