import React from 'react';
import { createUseStyles } from 'react-jss';
import { primaryColor } from '../styles';
import { ImageCircle } from '../components';
import { Helmet } from 'react-helmet-async';
import CreativeImage from '../assets/Creative-2.jpg';
import ExperienceImage from '../assets/experience.jpg';
import ProfessionalImage from '../assets/Professional.jpg';
import Stars from '../assets/stars-pattern-2.svg';
import SmilingGirl from '../assets/smiling-girl.jpg';
import SmilingGirls from '../assets/smiling-girls.jpg';
import SmilingMan from '../assets/smiling-dude.jpg';
import SmilingMan2 from '../assets/smiling-dude-2.jpg';
import SmilingMan3 from '../assets/smiling-dude-3.jpg';

const gallery = [
  {
    image: ExperienceImage,
    title: 'Experienced',
    // caption: 'Boutique Software Agency',
    blurb: 'We are a premiere boutique custom software development agency.  Delivering high quality results from high profile corporate clients, to small business websites.',
  },
  {
    image: ProfessionalImage,
    title: 'Professional',
    // caption: 'Client Focused',
    blurb: 'We treat all of our clients with the same laser focus.  Our systematic approach to exploration, documentation, execution, and delivery ensures speed, quality, and high client satisfaction.'
  },
  {
    image: CreativeImage,
    title: 'Creative',
    // caption: 'Software As An Art Form',
    blurb: 'This work is by nature fun.  Only by deliberate effort can it be made mundane.  We keep things creative, fully include all ideas and points of view.'
  },
];

const useStyles = createUseStyles({
  '@keyframes starFadeOut': {
    from: {textShadow: `0 0 8px ${primaryColor}`,},
    to: {textShadow: `0 0 0px ${primaryColor}`,},
  },
  '@keyframes starFadeIn': {
    from: {textShadow: `0 0 0px ${primaryColor}`,},
    to: {textShadow:`0 0 8px ${primaryColor}`,},
  },
  '@keyframes titleFadeOut': {
    from: {backgroundColor: '#ffffffcc'},
    to: {backgroundColor: 'transparent'},
  },
  '@keyframes titleFadeIn': {
    from: {backgroundColor: 'transparent'},
    to: {backgroundColor: '#ffffffcc'},
  },
  '@keyframes fadeIn' : {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  main: {
    background: `linear-gradient(to left, ${primaryColor}, #ffffff, #ffffff)`,
  },
  bannerTitle: {
    fontWeight: 400,
    fontSize: '3em',
    margin: 0,
    padding: '32px 0 64px 0',
    letterSpacing: '6px',
  },
  subtitle: {
    margin: 0,
    fontSize: '24px',
    letterSpacing: '1px',
  },
  gallery: {
    width: '100%',
    display: 'grid',
    columnGap: '16px',
    rowGap: '16px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    margin: 'auto',
    '@media (max-width: 500px)': {
      display: 'block',
    },
  },
  gridItem: {
    width: '80%',
    margin: '0 auto',
    '@media (max-width: 500px)': {
      margin: '64px auto 0 auto',
    },
  },
  gridItemImageBox: {
    height: '250px',
  },
  galleryTitle: {
    margin: 0,
    padding: '30px',
    fontSize: '20px',
    letterSpacing: '8px',
    backgroundColor: '#ffffffcc',
    animation: `$titleFadeIn 1000ms forwards`,
    '&:hover': {
      animation: `$titleFadeOut 1000ms forwards`,
    },
  },
  blurb: {
    letterSpacing: '2px',
    marginBottom: 0,
  },
  bottomDescription: {
    backgroundImage: `url(${Stars})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingBottom: '64px',
  },
  skillsContainer: {
    paddingTop: '120px',
  },
  skillsBanner: {
    backgroundColor: '#363636cc',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      overflow: 'hidden',
    },
  },
  bannerText: {
    fontSize: '24px',
    margin: 0,
    color: '#fff',
  },
  bannerStar: {
    color: 'yellow',
    fontSize: '24px',
  },
  imageContainer: {
    width: '100%',
    height: '180px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-25px'
  },
});

const About = () => {
  const classes = useStyles();
  const mobile = window.matchMedia('(max-width: 500px)').matches;
  const bannerTextArray = [
    'Trust',
    'Honesty',
    'Creativity',
    'Collaboration',
    'Intelligence'
  ];
  const bannerTextArrayMobile = [
    'Honesty',
    'Creativity',
    'Collaboration',
  ];
  const imageUrls = [
    SmilingMan2,
    SmilingGirls,
    SmilingMan,
    SmilingGirl,
    SmilingMan3
  ];
  const imageUrlsMobile = [
    SmilingMan,
    SmilingGirl,
    SmilingMan3
  ];
  
  const textArray = mobile ? bannerTextArrayMobile : bannerTextArray;
  const images = mobile ? imageUrlsMobile : imageUrls


  return (
    <div className={classes.main} id="about">
      {/* <Helmet>
        <title>Software Delight About Us</title>
        <meta name="description" content="Software Delight  About us custom software development agency and consulting. Denver, Colorado"/>
      </Helmet> */}
      <h2 className={classes.bannerTitle}>A premiere digital agency</h2>
      <p className={classes.subtitle}>Software as an art form</p>
      <div className={classes.gallery}>
        {
          gallery.map((item, index) => 
            <div className={classes.gridItem} key={index}>
              <div className={classes.gridItemImageBox} style={
                {
                  backgroundImage: `url(${item.image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}>
                <p className={classes.galleryTitle}>{item.title}</p>
              </div>
              <p className={classes.blurb}>{item.blurb}</p>
            </div>
          )
        }
      </div>
      <div className={classes.bottomDescription}>
        <div className={classes.skillsContainer}>
          <div className={classes.skillsBanner}>
          <p className={classes.bannerStar}>&#9734;</p>
            {
              textArray.map((word, index) =>
                <React.Fragment key={index}>
                  <p className={classes.bannerText}>{word}</p>
                  <p className={classes.bannerStar}>&#9734;</p>
                </React.Fragment>
              )
            }
          </div>
          <div className={classes.imageContainer}>
            {
              images.map((url, index) =>
                <React.Fragment key={index}>
                  <ImageCircle imageUrl={url} />
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
