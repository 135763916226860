import React from 'react';
import ServiceList from './ServiceList';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {

  },
  backgroundDiv: {
    background: 'linear-gradient(#fff, #6495EDcc)',
    padding: '64px',
    paddingBottom: '128px',
    '@media (max-width: 500px)': {
      padding: '64px 16px',
    },
  },
  cardTitle: {
    fontSize: '42px',
    fontWeight: 400,
    letterSpacing: '8px',

  },
  cardFlexContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '@media (max-width: 500px)': {
      display: 'block',
    }
  },
  cardImage: {
    maxHeight: '250px',
    border: '2px solid #363636',
    borderRadius: '8px',
    boxShadow: '2px 2px 4px #363636',
    '@media (max-width: 500px)': {
      width: '80%',
    },
  },
  cardTextContainer: {
    width: '40%',
    margin: '0 32px',
    '@media (max-width: 500px)': {
      margin: 0,
      width: '100%',
      textAlign: 'center',
    }
  },
  cardText: {
    textAlign: 'left',
    '@media (max-width: 500px)': {
      textAlign: 'left',
      width: '100%',
    }
  },
});

interface ServiceCardProps {
  imageUrl: string;
  imageAlt: string;
  title: string | JSX.Element;
  text: string;
  index: number;
  bulletPoints: string[];
  navAnchor: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ imageUrl, imageAlt, title, text, index, bulletPoints, navAnchor }) => {
  const classes = useStyles();
  const mobile = window.matchMedia('(max-width: 500px)').matches;

  const evaluateRenderOrder = (index: number) => {
    if (mobile) {
      return true;
    } else if (index % 2 === 0) {
      return true
    } else {
      return false
    }
  }
  
  return (
    <div className={classes.main}
      id={navAnchor}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={classes.backgroundDiv}>
        <h3 className={classes.cardTitle}>{title}</h3>
        <div className={classes.cardFlexContent}>
          {
            evaluateRenderOrder(index) ? (
              <>
                <img className={classes.cardImage} src={imageUrl} alt={imageAlt} />
                <div className={classes.cardTextContainer}>
                  <p className={classes.cardText}>{text}</p>
                  {
                    bulletPoints.map((item, index) => 
                      <React.Fragment key={index}>
                        <ServiceList listItem={item} />
                      </React.Fragment>
                    )
                  }
                </div>
              </>
            ) : (
              <>
                <div className={classes.cardTextContainer}>
                  <p className={classes.cardText}>{text}</p>
                  {
                    bulletPoints.map((item, index) => 
                      <React.Fragment key={index}>
                        <ServiceList listItem={item} />
                      </React.Fragment>
                    )
                  }
                </div>
                <img className={classes.cardImage} src={imageUrl} alt={imageAlt} />
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
