import React from 'react';
import { createUseStyles } from 'react-jss';
import { primaryColor } from '../styles';

const useStyles = createUseStyles({
  main: {},
  logoBlack: {
    margin: 0,
    lineHeight: '30px',
    letterSpacing: '2px',
    marginLeft: '2px',
    color: '#fff'
  },
  logoWhite: {
    margin: 0,
    color: '#ffffff',
    position: 'absolute',
  },
  logoPink: {
    margin: 0,
    color: primaryColor,
    position: 'absolute',
    marginLeft: '4px'
  },

});

interface LogoProps {
  scrollY: number;
}

const Logo: React.FC<LogoProps> = ({ scrollY }) => {
  const mobile = window.matchMedia('(max-width: 500px)').matches;
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      <h1 className={classes.logoBlack}
        style={{
          fontSize: mobile ? '42px' : scrollY > 100 ? '22px' : '42px',
          textShadow: mobile ? `0 0 18px ${primaryColor}` : scrollY > 100 ? `0 0 8px ${primaryColor}` : `0 0 12px ${primaryColor}`,
          transition: 'all .2s ease-in-out',
        }}
      >Software Delight</h1>
    </div>
  );
};

export default Logo;
