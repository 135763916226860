import React from 'react';
import Home from './Home';
import About from './About';
import Wrapper from './Wrapper';
import Services from './Services';
import Contact from './Contact';
import { Header, Footer } from '../components';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {
    height: '100vh',
    '@media (max-width: 500px)': {
      height: 'auto',
     },
  },
});

const components = [
  <Home />,
  <About />,
  <Services />,
  <Contact />,
]

const Main = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Header />
      {
        components.map((component, index) => 
          <React.Fragment key={index}>
            <Wrapper>
              {component}
            </Wrapper>
          </React.Fragment>
        )
      }
      <Footer />
    </div>
  )
}

export default Main;