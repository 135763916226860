import React from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {
    background: 'linear-gradient(#6495ED 20%, #6F8FAF)',
    height: '30px',
    width: '100%',
  }
});

interface DividerProps {

}

const Divider: React.FC<DividerProps> = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}></div>
  );
};

export default Divider;
