import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'
import emailjs from '@emailjs/browser';
import FormInput from './FormInput';
import FormLabel from './FormLabel';
import FormTextarea from './FormTextarea';
import InputSubmit from './InputSubmit';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

const key: string = process.env.REACT_APP_EMAIL_JS || '';
const template: string = process.env.REACT_APP_TEMPLATE_ID || '';
const service: string = process.env.REACT_APP_SERVICE_ID || '';

const useStyles = createUseStyles({
  main: {
    backgroundColor: '#ffffff55',
    borderRadius: '16px',
    border: '1px solid #fff',
    boxShadow: '2px 2px 4px #fff',
    margin: '0 20%',
    padding: '5% 0 2% 0',
    // minHeight: '280px',
    color: '#fff',
    textAlign: 'center',
    '@media (max-width: 500px)': {
      margin: '0 16px',
    },
  },
  form: {
    width: '80%',
    margin: 'auto',
  },
  title: {
    margin: '0 0 5% 0',
    fontSize: '2em',
    fontWeight: 500,
  }
});

interface ContactFormProps {
  formTitle: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ formTitle }) => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const classes = useStyles();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);

    const inputs = [...e.target];
    console.log("FORM inputs: ", inputs);
    let values: any = {};
    inputs.map((input) => {
      console.log(input.name);
      values[input.name] = input.value; 
    });
    if(values.zipcode) {
      console.log("Zipcode is filled in!");
      return;
    }

    try {
      emailjs.sendForm(service, template, e.target, {
        publicKey: key
      });
      setSuccess(true);
    } catch(e) {
      setError(true);
      console.error('Error in form submit: ', e);
    }
  };

  return (
    <div className={classes.main}>
      <h2 className={classes.title}>{formTitle}</h2>
      {
        error && !success ? (
          <ErrorMessage handleClick={setError} />
        ): null
      }
      {
        success && !error ? (
          <SuccessMessage handleClick={setSuccess} />
        ): null
      }
      {
        (!success && !error) || (success && error) ? (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormLabel htmlFor="fullname" labelText="Full Name *" />
            <FormInput type="text" name="fullname" id="fullname" required={true} />
            <FormLabel htmlFor="email" labelText="Email Address *" />
            <FormInput type="email" name="email" id="email" required={true}/>
            <FormLabel htmlFor="zipcode" labelText="Zipcode *" display={false} />
            <FormInput type="number" name="zipcode" id="zipcode" display={false} />
            <FormLabel htmlFor="message" labelText="Message *" />
            <FormTextarea name="message" id="message" required={true}/>
            <InputSubmit name="submit" id="submit" buttonText="Connect"/>
          </form>
        ) : null
      }
    </div>
  );
};

export default ContactForm;
