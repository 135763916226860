import React from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  '@keyframes open': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  '@keyframes close': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  },
  '@keyframes fadeIn' : {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  main: {
    width: '300px',
    height: '200px',
    position: 'absolute',
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    border: '#363636',
    borderRadius: '8px',
    padding: '16px',
  },
  burgerButton: {
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    '&.active': {
      opacity: 0.6
    }
  },
  navLink: {
    textDecoration: 'none',
    color: '#363636',
    margin: '16px 0',
    fontSize: '18px',
    // letterSpacing: '2px',
  }
});

interface NavSliderProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NavSlider: React.FC<NavSliderProps> = ({ navOpen, setNavOpen }) => {
  const classes = useStyles();

  const handleClick = () => {
    setNavOpen(false);
  }

  return (
    <div className={classes.main}>
      <a href='#' onClick={() => handleClick()} className={classes.navLink}>Home</a>
      <a href='#about' onClick={() => handleClick()} className={classes.navLink}>About</a>
      <a href='#services' onClick={() => handleClick()} className={classes.navLink}>Services</a>
      <a href='#contact' onClick={() => handleClick()} className={classes.navLink}>Contact</a>
    </div>
  );
};

export default NavSlider;
