import React from 'react';
import { createUseStyles } from 'react-jss';
import { primaryColor } from '../../styles';

const useStyles = createUseStyles({
  main: {
    width: '100%',
    textAlign: 'center',
    padding: '80px 0'
  },
  p: {
    letterSpacing: '4px',
    margin: '18px',
    fontSize: '18px',
  },
  button: {
    width: '120px',
    height: '60px',
    borderRadius: '8px',
    border: 0,
    marginBottom: '16px',
    backgroundColor: primaryColor,
    fontSize: '20px',
    fontWeight: 600,
    color: '#fff',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      opacity: 0.5
    }
  },
});

interface ErrorMessageProps {
  handleClick:  React.Dispatch<React.SetStateAction<boolean>>;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ handleClick }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      <p className={classes.p}>Oh no! An Error Occured</p>
      <p className={classes.p}>We were unable to send your message</p>
      <p className={classes.p}>Please try again.</p>
      <button className={classes.button} onClick={() => handleClick(false)}>Reload Form</button>
    </div>
  );
};

export default ErrorMessage;
