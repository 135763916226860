import React from 'react';
import CheckMark from './CheckMark';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listItem: {
    marginLeft: '16px'
  },
});

interface ServiceListProps {
  listItem: string;
}

const ServiceList: React.FC<ServiceListProps> = ({ listItem }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      <CheckMark />
      <p className={classes.listItem}>{listItem}</p>
    </div>
  );
};

export default ServiceList;
