import React, { MouseEvent, useState } from 'react';
import { createUseStyles } from 'react-jss'
import Burger from '../assets/burger-menu.svg';
import NavSlider from './NavSlider';
import { navigate } from './functions';

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'end',
    '@media (max-width: 500px)': {
      display: 'block',
      textAlign: 'right',
    },
  },
  burgerButton: {
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    '&.active': {
      opacity: 0.6
    }
  },
  navLink: {
    textDecoration: 'none',
    color: '#363636',
    margin: '10px',
    fontSize: '18px',
    letterSpacing: '2px',
  },
  close: {
    fontSize: '60px',
    fontWeight: 700,
    margin: '0 16px',
    transition: 'all 1s ease-out',
  }
});

interface NavProps {

}

const Nav: React.FC<NavProps> = () => {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const mobile = window.matchMedia('(max-width: 500px)').matches;
  const classes = useStyles();

  const handleClick = () => {
    setNavOpen(!navOpen);
  };
  
  return (
    <div className={classes.main}>
      {
        mobile ? (
          <button className={classes.burgerButton} onClick={handleClick}>
            {
              navOpen ? (
                <p className={classes.close}>X</p>
              ):(
                <img style={{transition: 'all 1s ease-out',}} src={Burger} alt='Hamburger Menu' height='80%' />
              )
            }
          </button>
        ): (
          <>
            <a href='/' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'home')}>Home</a>
            <a href='/about' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'about')}>About</a>
            <a href='/services' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'services')}>Services</a>
            <a href='/contact' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'contact')}>Contact</a>
          </>
        )
      }
      {
        navOpen ? (
          <NavSlider navOpen={navOpen} setNavOpen={setNavOpen} />
        ) : null
      }
    </div>
  );
};

export default Nav;
