import React from 'react';
import HomeImage from '../assets/home-image-1.jpg';
import ContactForm from '../components/contact-form/ContactForm';
import { createUseStyles } from 'react-jss';
import { primaryColor } from '../styles';
import { Helmet } from 'react-helmet-async';

const useStyles = createUseStyles({
  main: {
    backgroundImage: `url(${HomeImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: '100px',
    height: '100%',
  },
  background: {
    backgroundColor: 'rgba(54,54,54,0.8)',
    width: '100%',
    padding: '5% 0'
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    '@media (max-width: 500px)': {
      display: 'block',
    }
  },
  ctaBox: {
    width: '40%',
    textAlign: 'left',
    lineHeight: '5em',
    marginLeft: '5%',
    color: '#fff',
    '@media (max-width: 500px)': {
      width: '90%',
      lineHeight: 'aut1emo',
    }
  },
  title: {
    fontWeight: 400,
    fontSize: '5.2em',
    margin: 0,
    '@media (max-width: 500px)': {
      fontSize: '62px',
    }
  },
  subtitleTwo: {
    fontWeight: 400,
    fontSize: '25px',
    margin: 0,
    '@media (max-width: 500px)': {
      fontSize: '17px',
    }
  },
  contactFormBox: {
    width: '50%',
  },
  contactButton: {
    textDecoration: 'none',
    width: '120px',
    height: '60px',
    borderRadius: '8px',
    border: 0,
    marginBottom: '16px',
    backgroundColor: primaryColor,
    fontSize: '20px',
    fontWeight: 600,
    color: '#fff',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      opacity: 0.5
    }
  }
});


const Home = () => {
  const classes = useStyles();
  const mobile = window.matchMedia('(max-width: 500px)').matches;

  return (
    <div className={classes.main} id='home'>
      <Helmet>
        <title>Software Delight Custom Software Development Agency and Software Consulting</title>
        <meta name="description" content="Software Delight top custom software development agency and consulting. Denver, Colorado. Providing Embedded, IOT development.  Websites.  Custom Applications.  Softare Consulting." />
      </Helmet>
      <div className={classes.background}>
        <div className={classes.contentContainer}>
          <div className={classes.ctaBox}>
            <h3 className={classes.title}>Yes </h3>
            <h3 className={classes.title}>you've come to the right place.</h3>
            <h3 className={classes.subtitleTwo}>Professional  &nbsp;|&nbsp;  Creative  &nbsp;|&nbsp;  Software Solutions</h3>
          </div>
          {
            mobile ? (
              <button onClick={() => window.location.href = '#contact'} className={classes.contactButton}>Contact</button>
            ) : (
              <div className={classes.contactFormBox}>
                <ContactForm formTitle='How Can We Help?'/>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Home;
