import React from 'react';
import { createUseStyles } from 'react-jss'
import { primaryColor } from '../styles';

const useStyles = createUseStyles({
  main: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '1px solid #363636',
    background: `linear-gradient(transparent, ${primaryColor})`,
    boxShadow: '2px 2px 4px #363636'
  },
  checkmark: {
    margin: 'auto',
    fontWeight: 700,
  }
});

interface CheckMarkProps {

}

const CheckMark: React.FC<CheckMarkProps> = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      <p className={classes.checkmark}>&#x2713;</p>
    </div>
  );
};

export default CheckMark;
