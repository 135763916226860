import React, { MouseEvent } from 'react';
import Arrow from '../assets/Arrow.svg';
import { createUseStyles } from 'react-jss'
import { primaryColor } from '../styles';
import { navigate } from './functions';

const useStyles = createUseStyles({
  main: {
    borderRadius: '50%',
    backgroundColor: primaryColor,
    height: '60px',
    width: '60px',
  },
  navLink: {
    textDecoration: 'none',
    color: '#363636',
    margin: '1%',
    
  },
});

interface ArrowButtonProps {

}

const ArrowButton: React.FC<ArrowButtonProps> = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      <a href='#' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'home')}>
        <img height='60px' width='60px' src={Arrow} alt='up pointing arrow, return to top'/>
      </a>
    </div>
  );
};

export default ArrowButton;
