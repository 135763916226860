import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {},
  textarea: {
    height: '100px',
    width: '100%',
    borderRadius: '8px',
    marginBottom: '16px',
    fontSize: '16px'
  }
});

interface FormTextareaProps {
  name: string;
  id: string;
  required?: boolean;
}

const FormTextarea: React.FC<FormTextareaProps> = ({name, id, required = false}) => {
  const [value, setValue] = useState('');
  const classes = useStyles();

  const handleChange = (val: string) => {
    setValue(val);
  }
  
  return (
    <textarea
      className={classes.textarea}
      name={name}
      value={value}
      id={id}
      required={required}
      onChange={(e) => handleChange(e.currentTarget.value)}
    />
  );
};

export default FormTextarea;
