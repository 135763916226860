import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  main: {
    borderBottom: '1px solid #000',
  },
});


interface WrapperProps {
  children: ReactNode
}

const Wrapper: React.FC<WrapperProps> = ({children}) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>{children}</div>
  );
};

export default Wrapper;
