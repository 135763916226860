import React, { MouseEvent } from 'react';
import MailIcon from '../assets/mail-142.svg';
import Logo from './Logo';
import { ArrowButton } from '../components';
import { createUseStyles } from 'react-jss';
import { navigate } from './functions';

const useStyles = createUseStyles({
  main: {
    backgroundColor: '#363636',
    minHeight: '200px',
    padding: '32px',
    '@media (max-width: 500px)': {
      padding: '32px 16px',
      height: '1000px',
      width: 'auto'
     }
  },
  footerContainer: {
    color: '#fff',
    margin: 'auto',
  },
  columnsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    '@media (max-width: 800px)': {
     display: 'block'
    }
  },
  columnContainer: {
    letterSpacing: '2px',
    textAlign: 'left',
    padding: '0 40px',
    '@media (max-width: 500px)': {
      letterSpacing: 0,
      padding: 0,
     }
  },
  columnTitle: {
    fontSize: '24px',
    fontWeight: 700,
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
  },
  li: {
    opacity: 0.6,
    color: '#fff',
    margin: '16px 0'
  },
  navLink: {
    textDecoration: 'none',
    color: '#fff',
  },
  paragraph: {
    opacity: 0.6,
    lineHeight: '22px'
  },
  hr: {
    height: '1px',
    border: 'none',
    width: '80%',
    backgroundColor: '#ffffff66',
    margin: '16px auto 64px auto',
  },
  copyright: {
    marginTop: '32px'
  },
  toTopBox: {
    width: '95%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface FooterProps {

}

const Footer: React.FC<FooterProps> = () => {
  const classes = useStyles();
  const mobile = window.matchMedia('(max-width: 500px)').matches;
  const tablet = window.matchMedia('(max-width: 800px)').matches;

  const infoColumns = [
    {
      title: 'Software Delight',
      component: <Logo scrollY={0} />,
      listItems: [],
      paragraph: `Take your business to the next level.\n Enhance your productivity.\n
      Bring your vision to reality.
      Custom software, taylored to your specific use-case to enrich & engage users and your product experience.`,
    },
    {
      title: 'Services',
      listItems: [
        <a href='/custom_applications' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'custom_applications')}>Custom App Development</a>,
        <a href='/website_development'  className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'website_development')}>Website Devlopment</a>,
        <a href='/uiux_design' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'uiux_design')}>UI / UX Design</a>,
        <a href='/firmware_hardware' className={classes.navLink} onClick={(e: MouseEvent) => navigate(e, 'firmware_hardware')}>Firmware, Hardware, IOT Devices</a>,
      ],
      paragraph: '',
    },
    {
      title: 'Contact',
      listItems: [
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          <img height='30px' width='30px' src={MailIcon} alt='envelope' />
          <span style={{margin: '0 8px'}}>info@softwaredelight.com</span>
        </div>
      ],
      paragraph: '',
    }
  ];

  const calculateColumnWidth = (arrayLength: number) => {
    
    return tablet ? '80%' : (100 / arrayLength).toString() + '%'
  }
  
  return (
    <div className={classes.main}>
      <div className={classes.footerContainer}>
        <div className={classes.columnsContainer}>
          {
            infoColumns.map((column, index) =>   
              <div key={index} className={classes.columnContainer} style={{width: calculateColumnWidth(infoColumns.length)}}>
                  <p className={classes.columnTitle}>{column.title}</p>
                  {
                    column.component ? column.component : null
                  }
                  {
                    column.listItems.length ? (
                      <ul className={classes.ul}>
                        {
                          column.listItems.map((item, index) => 
                            <li key ={index} className={classes.li}>{item}</li>
                          )
                        }
                      </ul>
                    ): (
                      <p className={classes.paragraph}>{column.paragraph}</p>
                    )
                  }
              </div>
            )
          }
        </div>
        <div className={classes.toTopBox}>
          <ArrowButton />
        </div>
        <hr className={classes.hr} />
        <p className={classes.copyright}>&copy; Copyright 2024 Software Delight</p>
      </div>
    </div>
  );
};

export default Footer;
