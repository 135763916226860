import React from 'react';
import { ContactForm } from '../components';
import { createUseStyles } from 'react-jss'
import ContactImage from '../assets/Contact.jpg';
import { Helmet } from 'react-helmet-async';
import RockiesImage from '../assets/Rockies.jpg';

const useStyles = createUseStyles({
  main: {
    backgroundImage: `url(${RockiesImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  background: {
    backgroundColor: '#363636cc',
  },
  pageTitle: {
    fontWeight: 400,
    fontSize: '3em',
    margin: 0,
    padding: '48px 0 0 0',
    letterSpacing: '6px',
    opacity: 0.7
  },
  contactContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '64px 0',
    '@media (max-width: 500px)': {
      display: 'block',
    },
  },
  imageContainer: {
    width: '50%',
    textAlign: 'left',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  contactImage: {
    width: '70%',
    margin: '32px',
    borderRadius: '8px',
    border: '1px solid #ffffffcc'
  },
  contactFormContainer: {
    width: '50%',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  contactText: {
    fontSize: '24px',
    letterSpacing: '4px',
    padding: '0 32px',
    textAlign: 'left',
    color: '#ffffffcc',
    margin: 0,
    '@media (max-width: 500px)': {
      marginTop: '32px',
    },
  },
});

interface ContactProps {

}

const Contact: React.FC<ContactProps> = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>
      {/* <Helmet>
        <title>Contact Software Delight</title>
        <meta name="description" content="Contact form for Software Delight Custom Software Development Agency and Consuting. Denver, Colorado"/>
      </Helmet> */}
      <div className={classes.background}>
        <h2 id='contact' className={classes.pageTitle}>Exploration is Free</h2>
        <div className={classes.contactContainer}>
          <div className={classes.contactFormContainer}>
          <ContactForm formTitle={'Contact Us'}/>
          </div>
          <div className={classes.imageContainer}>
            <p className={classes.contactText}>
              We love this stuff. <br/><br/>
              Let us be your partner on your digital journey.<br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
