import React, { MouseEvent, useState } from 'react';

export const navigate = (e: MouseEvent, id: string) => {
  e.preventDefault();
  const elementToView = document.getElementById(id);
  console.log("ELEMENT TO VIEW: ", elementToView)
  if (elementToView) {
    elementToView.scrollIntoView()
  }
};
